import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "./Particle";
import homeLogo from "../Assets/home-main.svg";

import Card from 'react-bootstrap/Card';
import { FaRegEnvelope } from "react-icons/fa";
import { CiPhone,CiLinkedin } from "react-icons/ci";



function Contact() {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              Let's <strong className="purple">Talk</strong>
            </h1>

              <Card.Body>
                <blockquote className="blockquote mb-0">
                  <p style={{ textAlign: "justify" }}>

                    <FaRegEnvelope style={{ marginBottom: "2px" }} /> laurynas.brilingas@gmail.com
                    <br />
                    <CiPhone style={{ marginBottom: "2px" }} /> +370 686 74 005
                    <br />
                    <CiLinkedin style={{ marginBottom: "2px" }} /> <a className="purple" href="https://linkedin.com/in/laurynas-brilingas">Laurynas Brilingas</a>
                  </p>
                </blockquote>
              </Card.Body>
          </Col>
          
          <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "50px" }}
            className="about-img"
          >
            <img src={homeLogo} alt="about" className="img-fluid" />
          </Col>

        </Row>
        
        <Container fluid className="home-about-section" id="about">
          <Container>
            
            <Col md={8} className="home-about-description">
              </Col>
        
          </Container>
        </Container>
      </Container>
    </Container>
  );
}

export default Contact;
