import React from "react";
import { Col, Row } from "react-bootstrap";
import { FaSalesforce, FaReact} from "react-icons/fa";
import { TbSql } from "react-icons/tb";
import {
  DiGit,
  DiJava,
} from "react-icons/di";
import {
  SiSpring,
  SiPostgresql,
  SiOracle,
  SiDocker
} from "react-icons/si";


function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <DiGit />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiJava />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiSpring />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiOracle />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiDocker />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <TbSql />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPostgresql />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <FaSalesforce />
      </Col>      
      <Col xs={4} md={2} className="tech-icons">
        <FaReact />
      </Col> 
    </Row>
  );
}

export default Techstack;
