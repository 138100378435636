import React from "react";
import Card from "react-bootstrap/Card";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hello. I am <span className="purple">Laurynas Brilingas </span>
            from <span className="purple"> Vilnius, Lithuania</span>
            <br />
            <br />
            Most of my knowledge and experience comes from working in the <span className="purple"> finance industry</span>
            <br />
            <br />
            I have been developing software professionally for <span className="purple"> 3+ years</span>
            <br />
            <br />
            I have a <span className="purple">bachelor's degree</span> in Software Engineering
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
