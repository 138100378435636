import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";


function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              Let Me <span className="purple"> Introduce </span> Myself
            </h1>
            <p className="home-about-body">
              I wrote my <b className="purple">first line of code in 2014</b> during my time in school, and since then, I have embarked on a continuous journey in the field
              <br />
              <br />
              My main area of <b className="purple">expertise is backend development</b>
              <br />
              <br />
              When I don't code, I deepen my theoretical knowledge, learn about business processes, follow the startup scene, and read technical books
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
